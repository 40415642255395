.game-over-screen {
    position: fixed;
    top: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: dropDown 1s ease forwards, bounce 0.5s ease 1s forwards;
    z-index: 1000; /* Ensure it overlays everything */
  }

  .game-over-screen.win {
    background-color: #00a2ffc0;
  }

  .game-over-screen.lose {
    background-color: #ff4d4dc0;
  }
  
  .game-over-text {
    font-family: 'Comic Sans MS', cursive, sans-serif;
    font-size: 48px;
    font-weight: bold;
    text-align: center;
    color: rgba(255, 255, 255, 0.7);
    /* color: transparent; */
    line-height: 1.2;
  }
  
  @keyframes dropDown {
    to {
      top: 0;
    }
  }
  
  @keyframes bounce {
    0% {
      top: 0;
    }
    50% {
      top: -10px;
    }
    100% {
      top: 0;
    }
  }
  