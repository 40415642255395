

.trading-card {
    border: 1px solid #ccc;
    border-radius: 8px;
    width: 350px;
    padding: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #d8d8d8;
  }

  .trading-card-top {
    /* background-size: 350px 350px; */
    /* height: 200px; */
    display: flex;
    flex-direction: row;
  }

  .champ-stats {
    display: flex;
    flex-direction: row;
    /* width: 300px; */
    justify-content: space-between;
    /* border: 2px solid #ccc; */
    /* margin: auto; */
    width: 100%;
    /* background-color: #d8d8d8; */
    border-radius: 10px;
    padding: 2px;
  }
  
  .card-header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    width: 100%;
  }

  .zcard-image {
    width: 100%;
    margin-right: 16px;
    border-radius: 8px;
  }

  .trading-card h3, .trading-card h5, .trading-card h6 {
    margin: 0;
  }

  .card-header, .card-details {
    background-color: rgb(239, 239, 239);
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 2px;
  }

  
  