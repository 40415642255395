.action-list-container {
    width: 600px;
    /* max-width: 500px; */
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .search-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    /* border: 1px solid #c90000; */

  }
  
  .search-input {
    flex: 1;
    padding: 8px 12px;
    margin-right: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .filter-select {
    flex: 0.5;
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    margin-right: 10px;
  }
  
  .action-list {
    list-style: none;
    padding: 0;
  }
  
  .action-item {
    padding: 15px;
    margin-bottom: 10px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .action-item:hover {
    transform: translateY(-2px);
  }
  
  .action-name {
    margin: 0 0 10px;
    font-size: 18px;
    font-weight: 600;
  }
  
  .action-affinities,
  .action-skills {
    margin: 0;
    font-size: 14px;
    color: #666;
  }
  