.champion-selection {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.champion-card.selected {
    border: 2px solid #ff0000;
}

.action-selection {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.action-selection.your-pick {
    background-color: #ffeb39;
}

.action-selection .action-card {
    padding: unset;
    /* width: 400px; */
}
