/* .build-page-full {
} */

.main-page-layout {
    /* margin-left: 270px; */
    display: flex;
    position: relative;
}

h1 {
    background-color: #2c3e50;
    color: #ffe69c;
}

.draft-page-build {
    /* all: unset; */
    position: relative;
    display: flex;
    flex-direction: row;
    /* width: 100%; */
    /* justify-content: space-around; */
    align-items: space-around;
    align-content: space-around;
    justify-content: space-around;
    justify-items: space-around;


    margin-top: 40px;
    margin-left: 270px;
    /* scale: 0.8; */
}

.build-name-input {
    width: 100%;
    text-align: center;
    font-size: 20px;
    margin-bottom: 20px;
}

.draft-actions {
    all: unset;
}

.draft-page-build .build-champion-card .card-header:before {
    border: 2px solid gray;
    margin: 3px;
}

.draft-page-build .build-champion-card.selected .card-header {
    border: 5px solid #fbff1e;
}

.draft-page-build .save-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    color: blue;
    background-color: rgb(60, 212, 197);
    cursor: pointer;
    border-radius: 100%;
}

.draft-page-build .save-button:hover {
    background-color: #008f99;
}

.draft-champion {
    /* transform: scale(.8); */
    transform-origin: top;
}

.draft-actions, .draft-actions-backup {
    /* transform: scale(.8); */
    transform-origin: top left;
    position: relative;
    display: flex;
    transform-origin: top left;
}

.draft-actions-backup {
    margin-left: -110px;
}

.draft-champion, .draft-actions-backup, .draft-actions {
    transform: scale(var(--scale));
    /* transform: scale(1.); */
    /* border: #008f99 2px solid; */
}
