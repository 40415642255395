.build-page {    
    margin: auto;
    /* width: 500px; */
    display: flex;
    flex-direction: column;
}

.build-page-card-body {
    display: flex;
    flex-direction: row;
}

.build-header {
    position: relative;
    /* margin: auto; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(187, 0, 0);
    border-radius: 10px;
}

.build-page-card {
    margin: auto;
    position: relative;
     /* border: solid 3px black; */  /* border: solid 3px black; */
    /* border: solid 3px black; */
}

.build-actions {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: auto;
}

.build-header h1 {
    background-color: unset;
    /* background-color: transparent; */
    /* background-color: aqua; */
    margin: auto;
}