  
  .action-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 12px;
    padding: 10px;
    margin: 5px 0;
    transition: all 0.3s ease;
    font-weight: bold;
    white-space: pre-wrap;
    /* width: 350px; */
  }
  
  .action-card-text {
    font-weight: bold;
  }
  
  .action-card-cost, .action-card-position {
    margin-left: 10px;
    font-size: 0.9rem;
    color: #666;
  }

  .action-card-position {
    display: flex;
    flex-direction: row;
  }


  .action-card-cost {
    /* width: 40%; */
    white-space: nowrap;
    width: fit-content;
    /* margin-right: 5px; */
    background-color: rgb(126, 126, 126);
    border-radius: 8px;
    padding: 2px 7px 0px 5px;
    color: white;
  }
  
  .action-card-cost span, .action-card-position span {
    display: block;
  }
  
  .action-card-cost span::before, .action-card-position span::before {
    color: #000;
  }

  .action-card-header {
    margin-left: 10px;
  }
  
  .action-card-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 0.8rem;
    color: #000000;
  }

  .action-card-body {
    margin-left: 10px;
    font-size: .6rem;
    color: #666;
    margin-bottom: 5px;
  }

  .action-card-body p {
    margin: 0;
  }
  