.log-panel {
    background-color: #f0f0f0;
    margin: 20px;
    border-radius: 8px;
    /* max-height: 300px; */
    height: auto;
    overflow: hidden;
    /* border: 2px solid #7ac670; */
    white-space: pre-wrap;
  }
  
  .log-entry {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 12px;
    padding: 10px;
    margin: 5px 0;
    transition: all 0.3s ease;
    font-weight: bold;
  }

  .log-entry.latest-entry {
    transform: translateX(10px);
    font-size: 1.1rem;
    border: 1px solid #bbb;
  }
  
  .log-text {
    font-weight: bold;
  }
  
  .log-cost, .log-position {
    margin-left: 10px;
    font-size: 0.9rem;
    color: #666;
  }

  .log-position {
    display: flex;
    flex-direction: row;
  }


  .log-cost {
    width: 40%;
    background-color: rgb(126, 126, 126);
    border-radius: 8px;
    padding: 2px 2px 0px 5px;
    color: white;
  }
  
  .log-cost span, .log-position span {
    display: block;
  }
  
  .log-cost span::before, .log-position span::before {
    /* content: '• '; */
    color: #000;
  }

  .log-header {
    margin-left: 10px;
  }
  
  .log-footer {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: center;
    font-size: 0.8rem;
    color: #000000;
  }

  .log-body {
    margin-left: 10px;
    font-size: .6rem;
    color: #666;
  }

  .log-body p {
    margin: 0;
  }

  .log-body {
    margin-bottom: 5px;
  }
  