.actions {
  margin-top: 20px;
}

.actions button {
  margin: 0 5px;
  padding: 10px 20px;
  font-size: 16px;
}

.grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #fff;
  padding: 10px;
}

.grid-container {
  position: relative;
  display: flex;
  flex-direction: column;
  user-select: none;
}

.row {
  display: flex;
  align-items: center;
}

.tile {
  position: relative;
  width: 90px;
  height: 90px;
  background-color: #f0d9b5;
  /* background-color: #f0d9b5; */
  border: 1px solid #b58863;
  margin: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tile.row-0:nth-child(even){
  background-color: #b58863;
}

.tile.row-1:nth-child(odd){
  background-color: #b58863;
}

.tile.row-0.highlighted {
  background-color: yellow;
  border: 1px solid #ffd700;
}

.tile.row-1.highlighted {
  background-color: yellow;
  border: 1px solid #ffd700;
}

/* .tile.selected, .tile.row-1.selected, .tile.row-0.selected {
  background-color: #b4b700;
  border: 1px solid #517fff;
} */

[class^=tile][class*=row][class*=yours] {
  border: 1px solid #517fff;
  background-color: #517fff;
}

[class^=tile][class*=row][class*=theirs] {
  border: 1px solid #ff4d4d;
  background-color: #ff4d4d;
}

[class^=tile][class*=row][class*=selected], [class^=tile][class*=row][class*=highlighted] {
  /* scale: 10; */
  background-color: yellow;
  border: 1px solid #ffd700;
}

.piece {
  width: 82px;
  height: 82px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  border-radius: 50%;
  position: relative;
  /* border: 2px solid #000; */
}

.piece.Hidden {
  /* border: 2px solid #000; */
  opacity: .5;

}

.col-numbers {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
  margin-left: 20px;
}

.col-number {
  width: 94px;
  text-align: center;
  font-weight: bold;
}

.row-number {
  width: 20px;
  text-align: center;
  font-weight: bold;
}

.overlay {
  background-color: rgb(0, 0, 0, .4);
  /* margin: 1px; */
  padding: 1px;
  /* border-radius: 10px; */
}

.overlay.acted {
  position: absolute;
  top: 0;
  right: 0;
  /* background-color: rgba(0, 0, 0, 0.5); */
  /* border-radius: 50%; */
  color: yellow;
  /* background-color: transparent; */
  z-index: 3;
}

.overlay.movement {
  position: absolute;
  top: 22px;
  right: 0;
  /* background-color: rgba(0, 0, 0, 0.5); */
  /* border-radius: 50%; */
  color: rgb(255, 141, 1);
  /* background-color: transparent; */
  z-index: 3;
}

.arrow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* width: 300px; */
  /* height: 300px; */
  height: 100%;
  pointer-events: none;
  color: #517fff;
  z-index: 4;
  border: 2px solid #517fff;
}

.arrow.marker {
  border: 2px solid #517fff;
}

line {
  border: 2px solid #517fff;
  /* stroke: #b4b700; */
}

.custom-cursor {
  display: flex;
  position: absolute;
  pointer-events: none; /* Prevent blocking of other interactions */
  transform: translate(-50%, -50%);
  z-index: 1000; /* Ensure the custom cursor is on top */
  /* color: aqua; */
}

.grid-container.custom-on {
  cursor: none; /* Hide the default cursor */
}
