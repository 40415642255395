.trash-icon {
    color: #dc3545;
    font-size: 1.2em;
    cursor: pointer;
    transition: none;
}

.trash-icon:hover {
    color: #c82333;
}

.trash-icon-container {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1000000;

  }

/* .action-list-container {
    position: relative;
    overflow: visible;
} */

.build-actions-list .action-list {
    display: flex;
    flex-direction: column;
    /* border: #c82333 1px solid; */
    width: 400px;
}

.build-actions-list .action-item {
    position: relative;
}

.build-actions-list .action-item .trash-icon {
    display: none;
}

.build-actions-list .action-item:hover .trash-icon {
    display: block;
}

.build-actions-list .action-item.champion-action {
  background-color: rgb(197, 197, 197);
}

.build-actions-list .action-item.champion-action:hover .trash-icon {
  display: none;
}

.react-beautiful-dnd-dragging {
    transform-origin: top left !important; /* Align the origin */
    transform: scale(calc(1 / var(--scale))) !important; /* Adjust for scale */
}