.game-info {
    font-family: Arial, sans-serif;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    width: 380px;
  }

.current-player-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
  border-radius: 10px;
}

.actor-bar {
  width: 100%;
}

.actor-bar.not-shown {
  color: white;
  background-color: white;
}

/* .actor-bar.shown {
  border: 2px solid;
} */

.your-action {
  background-color: #00a2ff;
  color: #fff;
  padding: 10px;
  /* margin-bottom: 10px; */
  font-weight: bold;
}

.not-your-action {
  background-color: #ff4d4d;
  color: #fff;
  padding: 10px;
  /* margin-bottom: 10px; */
  font-weight: bold;
}