.user-builds-page {
    display: flex;
    flex-direction: column;
    margin-left: 270px;
    /* border: 2px solid red; */
}

.user-builds-page h1 {
    background-color: #2c3e50;
    color: #ffe69c;
}

.user-builds {
    all: unset;
    padding: 0 50px;
    display: flex;
    flex-wrap: wrap;
    /* border: 2px solid black; */
}

.user-build {
    all: unset;
    width: 150px;
    height: 80px;
    border: 2px solid black;
    font-size: medium;
    text-align: center;
    align-content: center;
    margin-right: 40px;
    border-radius: 10px;
}