.draft-page-build {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    /* height: 100%; */

}


.draft-actions .card .card-header  {
    background-color: rgb(63, 89, 255);
    color: white;
    /* padding: 0px; */
}

.draft-actions .card.selected .card-header  {
    border: 5px solid #fbff1e;
}

.draft-actions-backup .card .card-header  {
    background-color: white;
}