.navbar {
    position: fixed;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 250px;
    background-color: #2c3e50;
    padding: 20px;
}

.logo-container {
    margin-bottom: 0px;
    padding: 0;
}

.logo {
    width: 80px;
    height: 80px;
}

.nav-links {
    all: unset;
    display: flex;
    position: relative;
    flex-direction: column;
    list-style-type: none;
    /* margin-top: -3000px; */
}

.nav-links li {
    display: flex;
    position: relative;
}

.username {
    all: unset;
    display: flex;
    position: relative;
    text-decoration: none;
    color: white;
    font-size: 1.2em;
    width: 100%;
    align-items: center;
    text-align: left;
    border-radius: 5px;
    background-color: #1abc9c;
    transition: background-color 0.3s;
    padding: 10px;
    margin: 5px;
    justify-content: center;
}

.nav-button {
    all: unset;
    display: flex;
    position: relative;
    text-decoration: none;
    color: white;
    font-size: 1.2em;
    width: 100%;
    align-items: center;
    text-align: left;
    border-radius: 5px;
    background-color: #34495e;
    transition: background-color 0.3s;
    padding: 10px;
    margin: 5px;
}

.nav-links a:hover, .nav-button:hover {
    background-color: #1abc9c;
}

.sign-out {
    margin-top: 20px;
}
