.champion-page {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    margin: 20px;
    margin-top: 80px;
}

.build-buttons {
    position: fixed;
    top: 10px;
    left: 10px;
    right: 10px;
    z-index: 10;
    display: flex;
}

.build-buttons > *:last-child {
    margin-left: auto;
}

.champion-card {
    display: flex;
    margin: auto;
    margin-bottom: 20px;
    padding: 2px;
}

.champion-page .champion-card.selected {
    border: 2px solid rgb(251, 255, 22);
    padding: 0px;
}

.champion-page .user-build {
    padding: 50px;
}

.champion-page.custom {
    display: flex;
    position: relative;
    margin-top: 200px;
}

.disabled-button {
    opacity: 0.5;
    background-color: #101010;
}

.selected-champion-text {
    font-size: 18px;
    font-weight: bold;
    align-content: center;
    margin-top: 20px;
    margin-left: 100px;
}