.draft-tab-buttons {
    position: fixed;
    z-index: 10;
}

.draft-tab-buttons button {
    /* position: fixed; */
    padding: 10px;
    margin: 5px;
    cursor: pointer;
    border: 1px solid #ccc;
    background-color: #959595;
    font-weight: bold;
  }
  
.draft-tab-buttons button.active {
    background-color: #ddd;
}

.draft-tab-buttons button.selected-button {
    background-color: blue;
}

.draft-tab-buttons button.your-pick {
    background-color: rgb(255, 236, 24);
    color: black;
}

/* .draft-tab-content {
    padding: 20px;
    border: 1px solid #ccc;
    margin-top: 10px;
} */

/* .draft-page h1 {
    position: fixed;
    width: 100%;
    z-index: 0;
} */

.draft-tab-content .not-selected-tab {
    display: none;
}

.ready-button {
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 10px;
    padding: 10px;
    cursor: pointer;
    border: 1px solid #ccc;
    background-color: blue;
    font-weight: bold;
}

.waiting-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(71, 209, 255, 0.5);
    z-index: 10;
    text-align: center;
    align-content: center;
    font-size: large;
    font-weight: bold;
}
