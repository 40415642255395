/* App.css */

body {
  background-color: #f0f0f0;
  /* margin: 0; */
  /* padding: 0; */
  font-family: Arial, sans-serif;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* border: 2px solid #ff0000; */
  /* justify-content: center; */

  
  /* width: 250%; */
  /* height: 250%; */
  /* height: 15=00vh; */
}

.App {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* width: 100vw; */
  height: 100vh;
  /* border: 2px solid #0056b3; */
  overflow: hidden;
}

h1 {
  margin-top: 0;
  padding: 20px;
  background-color: #007bff;
  color: white;
  text-align: center;
}

.game-container {
  display: flex;
  flex-direction: row;
  /* width: 100%; */
  height: 100%;
  margin: auto;
  /* border: 2px solid #ff0000; */
  /* position: absolute; */
  /* scale: 2.0; */
}

.App.your-act {
  background-color: #b4b700;
}

button {
  margin-top: 20px;
  padding: 10px 20px;
  margin-right: 10px;
  font-size: 16px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.submitButton {
  font-weight: bold;
}

.submitButton.resign {
  background-color: #ba0000;
}

.submitButton.resign:hover {
  background-color: #830000;
}

button:hover {
  background-color: #0056b3;
}

.game-info-container {
  position: relative;
  border-radius: 10px;
  align-content: center;
  padding: 10px;
  width: 400px;
  overflow: hidden;
}

.game-info-container-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* margin-top: 10px; */
}

.game-info-container button {
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.Grid {
  /* width: 100%; */
  flex: 1;
  position: relative;
  border: 3px solid #0056b3;
  /* scale: .5; */

}

.end-panel {
  display: flex;
  flex-direction: column;
  /* height: auto; */
  height: 100%;
  /* border: 1px solid #ff0000; */
  border-radius: 10px;
  padding: 0px;
  background-color: #f0f0f0;
  overflow: hidden;
  /* overflow-y: scroll; */
  width: 400px;

}

.log-panel-holder {
  /* position: relative; */
  /* display: flex; */
  flex: 1;
  /* 1 auto; */
  overflow-y: scroll;
  height: 0px;
  overflow-x: hidden;
  /* border: 4px solid #ff0000; */
  /* z-index: 4; */
  /* height: 50vh; */
  width: 400px;
}

/* .log-panel p {
  white-space: pre-wrap;
} */

.actions-panel {
  display: flex;
  flex-direction: column;
  width: auto;
  flex: 3;
  overflow: scroll;
  /* height: 0px; */
  margin: 20px;
}

.action-button-container {
  display: flex;
  position: relative;
  width: 100%;
}

.action-button-container .cooldown-text {
  position: absolute;
  bottom: -8px;
  right: 16px;
  z-index: 5;
  padding: 5px;
  font-weight: bold;
  border-radius: 8px;
  font-size: 12px;
  background-color: black;
  color: white;
}

.action-button {
  width: 100%;
}

.action-button.cooldown {
  background-color: #8c8c8c;
}

.create-game {
  margin: auto;
  position: fixed;
  width: 99%;
  height: 99%;
  /* border: 3px solid #0056b3; */
  text-align: center;
  align-content: center;
  background-color: rgba(71, 209, 255, 0.5);
}

.create-game button {
  position: relative;
  /* margin-left: 50px; */
  /* margin: auto; */
  /* border: 2px solid #00b32a; */
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 0, 0, 0.5);
}

@keyframes favicon-flash {
  0% { background-color: transparent; }
  50% { background-color: #ffeb39; }
  100% { background-color: transparent; }
}

.favicon-flash {
  animation: favicon-flash 1s infinite;
}